<template>
    <div class="body">
        <div class="list-top">
            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/banner2022051401.png" class="list-top-img">
        </div>
        <div class="list-box">
            <div class="list-tab">
                <div v-for="item,index in typeList" :key="index" class="list-tab-box "
                     :class="typeId == item.id ? 'list-tab-box-activity' : ''"
                     @click="switchItems(item.id)">
                    <div>{{item.name}}</div>
                    <div>{{item.sub_name}}</div>
                </div>
            </div>
        </div>
        <div class="list-content" v-for="item,index in businessList" :key="index">
            <img class="list-content-img" v-if="item.exhibition_images"
                 :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/'+ item.exhibition_images[0]"
                 @click="viewBusiness(item.business_id)">
            <div class="list-content-matter">
                <div class="list-content-matter-title" @click="viewBusiness(item.business_id)">{{item.name}}</div>
                <div class="list-content-matter-star">
                    <div class="list-content-matter-star-img">
                        <van-rate v-model="item.show_star"
                                  :size="16"
                                  color="#DDA350"
                                  void-icon="star"
                                  void-color="#eee" readonly
                                  allow-half
                        />
                    </div>
                    <div class="list-content-matter-star-text">{{item.show_star}}分</div>
                </div>
                <div class="list-content-matter-label">
                    <div v-for="item2,index2 in item.label" :key="index2">{{item2}}</div>
                </div>
            </div>
            <div class="list-content-bottom" v-if="item.firstComment.comment">
                <div class="list-content-bottom-text">{{item.firstComment.comment}}</div>
                <div class="list-content-bottom-button" @click="viewCommentList(item.business_id)">我要点评</div>
            </div>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'

  export default {
    name: 'Comment',
    mixins: [globalConfig],
    data () {
      return {
        typeId: 1,
        typeList: [],
        businessList: []
      }
    },
    methods: {
      switchItems: function (id) {
        this.typeId = id
        this.getList(id)
      },
      init: function () {
        this.axios.post(this.apiUrl + 'mall/top_rank/getTopRankConfig').then((response) => {
          this.typeList = response.data.config
          console.log(this.typeList)
          //  配置生效，请求第一个
          this.typeId = this.typeList[0].id
          this.getList(this.typeList[0].id)
        })
      },
      getList: function (id) {
        this.axios.post(this.apiUrl + 'mall/top_rank/getTopRankList', {
          activityId: id
        }).then((response) => {
            console.log(response)
          this.businessList = response.data.list
        })
      },
      viewBusiness: function (id) {
        this.$router.push('/honor/store/' + id)
      },
      viewCommentList: function (id) {
        this.$router.replace('/commentList/1?pid=' + id)
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .list-box {
        width: 100vw;
        overflow-x: scroll;
        overflow-y: hidden;
        /*margin-top: 2vw;*/
        padding: 0 4vw
    }

    .list-tab {
        display: flex;
    }

    .list-tab-box {
        width: 250px;
        height: 54px;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/bg2022051402.png") no-repeat;
        background-size: 250px 54px;
        color: #999;
        font-size: 2.8vw;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 5vw;
        margin-top: 5vw;
    }

    .list-tab-box-activity {
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/bg2022051401.png") no-repeat;
        background-size: 250px 54px;
        color: #DEB67C;
    }

    .list-tab-box div {
        width: 70vw;
        text-align: center;
    }

    .list-tab-box div:nth-of-type(2) {
        margin-top: -5vw;
    }

    .body {
        width: 100vw;
        min-height: 100vh;
        background: #000;
        padding-bottom: 5vw;
    }

    .list-top-img {
        width: 100vw;
    }

    .list-content {
        width: 92vw;
        margin: 5vw 4vw 0;
        border-radius: 4vw;
        background: #ffffff;
    }

    .list-content-img {
        width: 92vw;
        border-radius: 4vw 4vw 0 0;
    }

    .list-content-matter {
        width: 92vw;
    }

    .list-content-matter-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 18px;
        color: #333;
        padding: 2vw 4vw 0 4vw;
    }

    .list-content-matter-star {
        padding: 2vw 4vw 2vw 4vw;
        display: flex;
        align-items: center;
    }

    .list-content-matter-star-text {
        color: #DDA350;
        font-size: 10px;
        margin-left: 3vw;
        font-weight: 600;
    }

    .list-content-matter-label {
        display: flex;
        flex-wrap: wrap;
        margin: 0 4vw 0 4vw;
    }

    .list-content-matter-label div {
        height: 26px;
        line-height: 26px;
        color: #666;
        background: #ccc;
        border-radius: 5px;
        padding: 0 4vw;
        margin: 0 3vw 2vw 0;
        font-size: 13px;
        font-weight: 400;
    }

    .list-content-bottom {
        width: 92vw;
        height: 12.5vw;
        background: #F8F0E5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4vw;
        border-radius: 0 0 4vw 4vw;
    }

    .list-content-bottom-text {
        width: 55vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #666;
        font-size: 14px;
        font-weight: 400;
    }

    .list-content-bottom-button {
        width: 93px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        color: #fff;
        background: #DDA350;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.3vw;
    }
</style>
